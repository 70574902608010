import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import { useTranslation } from "react-i18next";

const OpenPositions = () => {
  const { t } = useTranslation();
  const openPositionsContent = {
    title: `${t("careers.openPositionsHeadline")}`,
    cards: [
      {
        position: `${t("careers.openPositions.projectManager.position")}`,
        location: "Novi Sad / Beograd",
        url: "/careers/project-manager/",
      },
      {
        position: `${t("careers.openPositions.uiuxDesigner.position")}`,
        location: "Novi Sad / Beograd",
        url: "/careers/medior-ui-ux-designer/",
      },
      {
        position: `${t("careers.openPositions.reactNativeDeveloper.position")}`,
        location: "Novi Sad / Beograd",
        url: "/careers/senior-react-native-developer/",
      },
    ],
  };
  return (
    <section id="jobs" className="m-openPositions section-padding">
      <div className="_wr">
        <div className="m-openPositions__titleLine">
          <h2 className="m-openPositions__title">
            {openPositionsContent.title}
          </h2>
          <span className="a-line -red"></span>
        </div>
        <div className="m-openPositions__cards">
          <div className="_w">
            {openPositionsContent.cards.map(
              ({ position, location, url }, key) => (
                <div className="m-openPositions__content _12 _m6" key={key}>
                  <Link to={url} className="m-openPositions__card" key={key}>
                    <h3 className="m-openPositions__position">{position}</h3>
                    <div className="m-openPositions__card--content">
                      <div className="m-openPositions__info">
                        <p>
                          {t("careers.location")} <span>{location}</span>
                        </p>
                      </div>
                    </div>
                    <div className="m-cardLinks">
                      <button to={url} className="a-btn -tertiary">
                        {t("careers.jobPosition")}
                      </button>
                      <span className="a-arrow -long"></span>
                    </div>
                  </Link>
                </div>
              )
            )}
            {openPositionsContent.cards.length === 0 ? (
              <span className="flex justify-center _12 light-text">
                There are no open positions available at the moment.
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OpenPositions;
